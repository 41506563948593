import { useState } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";

import "./add-sub-btn.css";

function AddSubBtn({ minVal, maxVal, currentValue, changeCurrentNumber }) {
  const [currentNumber, setCurrentNumber] = useState(currentValue);

  const minusBtnClick = () => {
    let tempVal = currentNumber - 1;

    setCurrentNumber(tempVal);
    changeCurrentNumber(tempVal);
  };

  const plusBtnClick = () => {
    let tempVal = currentNumber + 1;

    setCurrentNumber(currentNumber + 1);
    changeCurrentNumber(tempVal);
  };

  return (
    <div>
      <div className="number-div">
        <span id="minusBtn" className={`minus ${currentNumber === minVal && "btn-set-disable"}`} onClick={minusBtnClick}>
          <AiOutlineMinusCircle />
        </span>
        <label>{currentNumber}</label>
        <span id="plusBtn" className={`plus ${currentNumber === maxVal && "btn-set-disable"}`} onClick={plusBtnClick}>
          <AiOutlinePlusCircle />
        </span>
      </div>
    </div>
  );
}

export default AddSubBtn;
