import { useState } from "react";

import AddSubBtn from "./add-sub-btn/add-sub-btn";

import "./num-of-tourist.css";

function NumOfTourist({ touristNumberAns, changeCurrentStep }) {
  const currentStep = 2;
  const [adultsNumber, setAdultsNumber] = useState(touristNumberAns.adultsNumber);
  const [kidsNumber, setKidsNumber] = useState(touristNumberAns.kidsNumber);
  const [kidsAgeList, setKidsAgeList] = useState(touristNumberAns.kidsAgeList);
  const kidsMaxAge = 12;

  const handleChangeAdult = (number) => {
    setAdultsNumber(number);
  };

  const handleChangeKid = (number) => {
    let tempAgeList = kidsAgeList;

    if (number > kidsNumber) {
      tempAgeList[number - 1] = kidsMaxAge;
    } else {
      tempAgeList.pop();
    }

    setKidsAgeList(tempAgeList);
    setKidsNumber(number);
  };

  const kidsAgeChange = (e, index) => {
    let tempAgeList = kidsAgeList;

    tempAgeList[index] = parseInt(e.target.value);
    setKidsAgeList(tempAgeList);
  };

  const numOfTouristBtn = () => {
    changeCurrentStep(currentStep, 3, {
      adultsNumber: adultsNumber,
      kidsNumber: kidsNumber,
      kidsAgeList: kidsAgeList,
    });
  };

  return (
    <div className="num-of-tourist center">
      {/* <div className="row mb-4">
        <div className="col">
          <h4></h4>
        </div>
      </div> */}
      <div className="container">
        <div className="row mt-4 d-flex justify-content-center">
          <div className="col-sm-4 mb-1 button-global-style-3">
            <img className="step-num-img-icon" src="/images/step-icons/num-adlt.svg" alt="step" />
            <p className="num-of-people-p margin-bottom-set-50">¿Cuantos adultos Viajan?</p>
            <AddSubBtn minVal={1} maxVal={5} currentValue={adultsNumber} changeCurrentNumber={handleChangeAdult} />
          </div>
          <div className="col-sm-4 button-global-style-3">
            <img className="step-num-img-icon" src="/images/step-icons/num-kids.svg" alt="step" />
            <p className="num-of-people-p">
              ¿Cuantos niños <span className="kids-age-p">(menores de 12 años)</span> Viajan?
            </p>
            <AddSubBtn minVal={0} maxVal={4} currentValue={kidsNumber} changeCurrentNumber={handleChangeKid} />
          </div>
        </div>
        <div className="col-sm-12 mt-4 d-flex justify-content-center adult-number-dropdown">
          {kidsNumber > 0 && <p className="kids-age-select">Edad del niño: </p>}
          {kidsAgeList &&
            kidsAgeList.map((value, i) => {
              return (
                <select defaultValue={value} className="age-set-input-box" onChange={(e) => kidsAgeChange(e, i)} key={i}>
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                  <option value={9}>9</option>
                  <option value={10}>10</option>
                  <option value={11}>11</option>
                  <option value={12}>12</option>
                </select>
              );
            })}
        </div>
        <div className="row mt-2 mb-2 d-flex justify-content-center">
          <div className="col-sm-6">
            <button type="button" className="step-button step-button-active" onClick={() => numOfTouristBtn()}>
              Próximo Paso
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NumOfTourist;
