export const DoHaveDatesAnswers = {
  yes: 0,
  no: 1,
};

export const TravelMonthAnswers = {
  first: "Ene - Mar",
  second: "Abr - Jun",
  third: "Jul - Sept",
  fourth: "Oct - Dic",
  fifth: "No estoy seguro",
};

export const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const doHaveDatesList = [810, 811];
export const travelMonthList = ["Ene - Mar", "Abr - Jun", "Jul - Sept", "Oct - Dic", "No estoy seguro"];
export const numOfAdultsList = [852, 853, 854, 855, 856];
export const numOfKidsList = [857, 858, 859, 860, 861];
