import React from "react";
import styled from "styled-components";
import { MdGridView } from "react-icons/md";

import CheckMark from "../../assets/svg/Checkmark";

export default function PricingTable({ packageData, action }) {
  return (
    <Wrapper className="whiteBg radius8 shadow package-price-div">
      <div className="flexSpaceCenter">
        <p className="font20 extraBold redColor">{packageData?.price}</p>
        {/* <p className="font20 extraBold">{numPerson}</p> */}
      </div>
      <div className="flexSpaceCenter">
        <p className="font20 extraBold">{packageData?.numPerson}</p>
      </div>
      <div className="flexSpaceCenter package-price-card-img">
        <img className="package-card-img" src={packageData?.img} alt="card"></img>
        <MdGridView
          size={35}
          className="package-img-close"
          onClick={() => {
            action(packageData?.planImg);
          }}
        />
      </div>
      <div style={{ margin: "30px 0" }}>
        <h4 className="font20 extraBold">{packageData?.title}</h4>
        <p className="font13">{packageData?.text}</p>
      </div>
      <div>
        {packageData?.offers
          ? packageData?.offers.map((item, index) => (
              <div className="flexNullCenter" style={{ margin: "5px 0" }} key={index}>
                <div style={{ position: "relative", top: "-1px", marginRight: "15px" }}>
                  {item.cheked ? (
                    <div style={{ minWidth: "15px" }}>
                      <CheckMark />
                    </div>
                  ) : (
                    <div style={{ minWidth: "20px" }}></div>
                  )}
                </div>
                <p className="font15">{item.name}</p>
              </div>
            ))
          : null}
      </div>
      {/* <div style={{ maxWidth: "150px", margin: "30px auto 0 auto" }}>
        <FullButton title="Reservar Ahora" action={action} />
      </div> */}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  text-align: left;
  padding: 20px 10px;
  margin-top: 30px;
`;
