export const packages = [
  {
    price: "$499 por 4 Personas",
    numPerson: "4 Días 3 Noches",
    title: "Villa de 1 Dormitorio",
    img: "/images/theme/packages/1.webp",
    planImg: "/images/theme/packages/1p.webp",
    text: "Siéntase como en casa en nuestra villa de un dormitorio que cuenta con más espacio que la habitación de hotel promedio. Una opción popular entre las familias pequeñas.",
    offers: [
      { name: "660 pies cuadrados", cheked: true },
      { name: "Duerme hasta 4 Huéspedes", cheked: true },
      { name: "Combinación de baño completo con ducha/bañera de hidromasaje", cheked: true },
      { name: "Microondas", cheked: true },
      { name: "Televisión por cable con canales premium", cheked: true },
    ],
  },
  {
    price: "$599 por 6 Personas",
    numPerson: "4 Días 3 Noches",
    title: "Villa de 2 Dormitorio",
    img: "/images/theme/packages/3.webp",
    planImg: "/images/theme/packages/1p.webp",
    text: "Unas vacaciones no son vacaciones sin un buen lugar para quedarse, y estará más que satisfecho con una villa de dos dormitorios aquí en Westgate Vacation Villas Resort.",
    offers: [
      { name: "776 pies cuadrados", cheked: true },
      { name: "Duerme hasta 6 Huéspedes", cheked: true },
      { name: "Combinación de baño completo con ducha/bañera de hidromasaje", cheked: true },
      { name: "1 cama King, 1 cama Queen, 2 camas individuales", cheked: true },
      { name: "Televisión por cable con canales premium", cheked: true },
    ],
  },
  {
    price: "$699 por 6 Personas",
    numPerson: "6 Días 5 Noches",
    title: "Villa de 2 Dormitorio",
    img: "/images/theme/packages/3.jpg",
    planImg: "/images/theme/packages/3p.webp",
    text: "Unas vacaciones no son vacaciones sin un buen lugar para quedarse, y estará más que satisfecho con una villa de dos dormitorios aquí en Westgate Vacation Villas Resort.",
    offers: [
      { name: "776 pies cuadrados", cheked: true },
      { name: "Duerme hasta 6 Huéspedes", cheked: true },
      { name: "Combinación de baño completo con ducha/bañera de hidromasaje", cheked: true },
      { name: "1 cama King, 1 cama Queen, 2 camas individuales", cheked: true },
      { name: "Televisión por cable con canales premium", cheked: true },
    ],
  },
  {
    price: "$799 por 8 Personas",
    numPerson: "4 Días 3 Noches",
    title: "Villa de lujo de 2 Dormitorios",
    img: "/images/theme/packages/5.webp",
    planImg: "/images/theme/packages/3p.webp",
    text: "Tres palabras: Espacio. Comodidad. Asequibilidad. Estos resumen una estadía en nuestra villa de lujo de dos dormitorios. Espere mucho espacio para una familia o hasta ocho invitados.",
    offers: [
      { name: "800 pies cuadrados", cheked: true },
      { name: "Duerme hasta 8 Huéspedes", cheked: true },
      { name: "Balcón o patio con juego de comedor de dos sillas", cheked: true },
      { name: "2 Baños", cheked: true },
      { name: "Baño principal con bañera de hidromasaje y ducha independiente", cheked: true },
    ],
  },
  {
    price: "$899 por 8 Personas",
    numPerson: "6 Días 5 Noches",
    title: "Villa de lujo de 2 Dormitorios",
    img: "/images/theme/packages/5.jpg",
    planImg: "/images/theme/packages/5p.webp",
    text: "Tres palabras: Espacio. Comodidad. Asequibilidad. Estos resumen una estadía en nuestra villa de lujo de dos dormitorios. Espere mucho espacio para una familia o hasta ocho invitados.",
    offers: [
      { name: "800 pies cuadrados", cheked: true },
      { name: "Duerme hasta 8 Huéspedes", cheked: true },
      { name: "Balcón o patio con juego de comedor de dos sillas", cheked: true },
      { name: "2 Baños", cheked: true },
      { name: "Baño principal con bañera de hidromasaje y ducha independiente", cheked: true },
    ],
  },
  {
    price: "$1200 por 10 Personas",
    numPerson: "6 Días 5 Noches",
    title: "Villa de 2 Dormitorios con Altillo",
    img: "/images/theme/packages/3.webp",
    planImg: "/images/theme/packages/5p.webp",
    text: "Despiértate en una acogedora villa de dos dormitorios con todo lo que necesitas y más. Con más pies cuadrados que la típica habitación de hotel, cada villa viene con privacidad y espacio.",
    offers: [
      { name: "1,000 pies cuadrados", cheked: true },
      { name: "Duerme hasta 10 Huéspedes", cheked: true },
      { name: "Balcón o patio con juego de comedor de dos sillas", cheked: true },
      { name: "Baño completo con plato de ducha", cheked: true },
      { name: "Televisión por cable con canales premium", cheked: true },
      { name: "Bar humedo", cheked: true },
    ],
  },
];
