import { useState } from "react";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import { es } from "date-fns/locale";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import "./date-range.css";

function DateRangeStep({ dateRangesAns, changeCurrentStep }) {
  const currentStep = 1;
  const [dateRange, setDateRange] = useState([dateRangesAns]);
  const [validDate, setValidDate] = useState(true);

  const handleSelect = (range) => {
    setDateRange([range.selection]);

    if (range.selection.startDate === range.selection.endDate) {
      setValidDate(true);
    } else {
      setValidDate(false);
    }
  };

  const dateRangeSelectBtn = () => {
    changeCurrentStep(currentStep, 2, dateRange[0]);
  };

  return (
    <div className="do-you-have-dates center">
      <div className="row mb-4">
        <div className="col">
          <h4>Seleccione sus fechas de viaje</h4>
        </div>
      </div>
      <div className="row mb-2  d-flex justify-content-center">
        <div className="col-sm-12">
          <DateRange
            ranges={dateRange}
            onChange={(value) => handleSelect(value)}
            editableDateInputs={false}
            moveRangeOnFirstSelection={false}
            minDate={addDays(new Date(), 2)}
            showMonthAndYearPickers={true}
            months={1}
            direction={window.innerWidth >= 600 ? "horizontal" : "vertical"}
            locale={es}
          />
        </div>
      </div>
      <div className="row mb-2 d-flex justify-content-center">
        <div className="col-sm-6">
          <button type="button" className="step-button step-button-active" onClick={() => dateRangeSelectBtn()} disabled={validDate}>
            Próximo Paso
          </button>
        </div>
      </div>
    </div>
  );
}

export default DateRangeStep;
