import { useState } from "react";
import { TravelMonthAnswers } from "../../../../config/constants";

import StepButton from "../step-button/step-button";

import "./travel-months.css";

function TravelMonths({ travelMonthsAns, changeCurrentStep }) {
  const currentStep = 1;
  const [answer, setAnswer] = useState(travelMonthsAns);

  const clickTravelMonths = (value) => {
    setAnswer(value);
    changeCurrentStep(currentStep, 2, value);
  };

  return (
    <div className="do-you-have-dates center">
      <div className="row mb-4">
        <div className="col">
          <img className="step-img-icon" src="/images/step-icons/step-2-icon.svg" alt="step" />
          <h4>¿Cuando Piensa Viajar?</h4>
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers.first}
            text="Ene - Mar"
            btnClicked={() => clickTravelMonths(TravelMonthAnswers.first)}
          />
        </div>
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers.second}
            text="Abr - Jun"
            btnClicked={() => clickTravelMonths(TravelMonthAnswers.second)}
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers.third}
            text="Jul - Sept"
            btnClicked={() => clickTravelMonths(TravelMonthAnswers.third)}
          />
        </div>
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers.fourth}
            text="Oct - Dic"
            btnClicked={() => clickTravelMonths(TravelMonthAnswers.fourth)}
          />
        </div>
      </div>
      <div className="row d-flex justify-content-center">
        <div className="col-sm-5 mb-2">
          <StepButton
            answer={answer}
            value={TravelMonthAnswers.fifth}
            text="No estoy seguro"
            btnClicked={() => clickTravelMonths(TravelMonthAnswers.fifth)}
          />
        </div>
      </div>
    </div>
  );
}

export default TravelMonths;
