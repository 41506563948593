import React from "react";

import ReactCountryFlag from "react-country-flag";

import "./BranchDetails.css";

function BranchDetails({ country, number, countryCode }) {
  return (
    <div className="col-sm-3 col-6">
      <div className="branch-details-div">
        <p>
          <ReactCountryFlag
            countryCode={countryCode}
            svg
            style={{
              width: "3em",
              height: "3em",
            }}
            title={countryCode}
          />
        </p>
        <p className="branch-head text-size-22">{country}</p>
        <a href={`tel:${number}`} className="branch-tel text-size-16">
          {number}
        </a>
        {/* <p className="branch-address text-size-16">{address}</p> */}
      </div>
    </div>
  );
}

export default BranchDetails;
