import React from "react";
import styled from "styled-components";
import { Link } from "react-scroll";

export default function Contact() {
  const getCurrentYear = () => {
    return new Date().getFullYear();
  };

  return (
    <Wrapper>
      <div className="darkBg">
        <div className="container footer-div">
          <InnerWrapper className="flexSpaceCenter" style={{ padding: "30px 0" }}>
            <Link className="flexCenter animate pointer" to="home" smooth={true} offset={-80}>
              <img className="footer-logo-img" src="/images/logo_white.png" alt="alt" />
            </Link>
            <StyleP className="whiteColor font15">
              © {getCurrentYear()} - © Copyright © 2006 - 2022 ★ Todos los derechos reservados ★ bookingpromociones.com{" "}
            </StyleP>

            <Link className="whiteColor animate pointer font15" to="home" smooth={true} offset={-80}>
              Volver Arriba
            </Link>
          </InnerWrapper>
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;
const InnerWrapper = styled.div`
  @media (max-width: 550px) {
    flex-direction: column;
  }
`;
const StyleP = styled.p`
  @media (max-width: 550px) {
    margin: 20px 0;
  }
`;
