import axios from "axios";

export const getIpDetails = async () => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_IPIFY_URL}`,
      data: [],
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data;`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getLocationDetails = async (ip) => {
  try {
    const { data } = await axios({
      method: "get",
      url: `${process.env.REACT_APP_WEATHER_API_URL}`,
      params: { q: ip },
      headers: {
        "X-RapidAPI-Host": "weatherapi-com.p.rapidapi.com",
        "X-RapidAPI-Key": `${process.env.REACT_APP_RAPID_API_KEY}`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const getCityList = async (word) => {
  try {
    const token = "TOKEN";
    let formData = new FormData();

    formData.append("q", word);
    formData.append("token", token);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/city/filter.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data;`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};

export const submitLead = async ({
  firstName,
  lastName,
  email,
  phoneNumber,
  city,
  doHaveDates,
  travelMonths,
  travelStartDate,
  travelEndDate,
  numOfAdults,
  numOfKids,
  kidsAgeList,
  ipLocation,
  utmSource,
  utmContent,
  medium,
}) => {
  try {
    const token = "TOKEN";
    let formData = new FormData();

    formData.append("firstName", firstName);
    formData.append("lastName", lastName);
    formData.append("email", email);
    formData.append("phoneNumber", phoneNumber);
    formData.append("city", city);
    formData.append("doHaveDates", doHaveDates);
    formData.append("travelMonths", travelMonths);
    formData.append("travelStartDate", travelStartDate);
    formData.append("travelEndDate", travelEndDate);
    formData.append("numOfAdults", numOfAdults);
    formData.append("numOfKids", numOfKids);
    formData.append("kidsAgeList", kidsAgeList);
    formData.append("ipLocation", ipLocation);
    formData.append("sourceTitle", "Westgate Lake Landing");
    formData.append("sourceId", "38");
    formData.append("sourceUrl", window.location.hostname);
    formData.append("token", token);
    formData.append("utmSource", utmSource);
    formData.append("utmContent", utmContent);
    formData.append("medium", medium);

    const { data } = await axios({
      method: "post",
      url: `${process.env.REACT_APP_API_HOST}/bitrix/create_lead.php`,
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": `multipart/form-data;`,
      },
    });

    return data;
  } catch (error) {
    if (error.response) {
      return error.response.data; // => the response payload
    } else {
      //console.log(error);
    }
  }
};
