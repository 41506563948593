import React from "react";
import { Col, Row } from "react-bootstrap";
import { branchList } from "../../assets/data/branch";

import BranchDetails from "../BranchDetails/BranchDetails";

function ContactUs() {
  return (
    <div id="contact">
      <div className="container">
        <div className="send-us-links-div">
          <Row className="justify-content-md-start">
            <Col lg="8">
              <h1 className="font40 extraBold">Numeros alrededor del Mundo</h1>
              <p className="font15">Contamos con un buen equipo alrededor del mundo que ayuda a mejorar nuestro camino</p>
            </Col>
          </Row>
          <div className="row justify-content-center pt-3">
            {branchList.map((object, i) => (
              <BranchDetails {...object} key={i} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
