import { branchList } from "../assets/data/branch";

export const getPhoneNumber = (country) => {
  let returnValue = "";

  if (branchList && branchList.length > 0) {
    for (let i = 0; i < branchList.length; i++) {
      if (branchList[i]?.countryCode === country) {
        returnValue = branchList[i].number;
        break;
      }
    }

    if (returnValue === "") {
      returnValue = branchList[0].number;
    }
  }

  return returnValue;
};
