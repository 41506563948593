export const branchList = [
  { country: "Miami Florida", number: "+1 786-432-1356", countryCode: "US" },
  { country: "Colombia", number: "+57 (1) 514-1397", countryCode: "CO" },
  { country: "Mexico", number: "+52 (55) 46319967", countryCode: "MX" },
  { country: "Argentina", number: "+54 (11) 59844025", countryCode: "AR" },
  { country: "Costa Rica", number: "+506 400 18796", countryCode: "CR" },
  { country: "Rep. Dominicana", number: "+ (829) 946-3769", countryCode: "DO" },
  { country: "Honduras", number: "80027919130", countryCode: "HN" },
  { country: "Panamá", number: "+ 507 832-7606", countryCode: "PA" },
  { country: "Uruguay", number: "04135983171", countryCode: "UY" },
  { country: "Chile", number: "+ 56 ( 2 ) 32104775", countryCode: "CL" },
  { country: "Guatemala", number: "+502 24582827", countryCode: "GT" },
  { country: "El Salvador", number: "+ 503 21361298", countryCode: "SV" },
  { country: "Per\u00fa", number: "+ 51 (1) 7058267", countryCode: "PE" },
];
