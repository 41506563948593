import React from "react";
import styled from "styled-components";
// Components
import ClientSlider from "../Elements/ClientSlider";
import FullButton from "../Buttons/FullButton";
import Pricing from "./Pricing";
import ImageGallery from "react-image-gallery";

import "react-image-gallery/styles/css/image-gallery.css";
import { Col, Container, Row } from "react-bootstrap";

const images = [
  {
    original: "/images/theme/g/1.png",
    thumbnail: "/images/theme/g/1.png",
  },
  {
    original: "/images/theme/g/2.png",
    thumbnail: "/images/theme/g/2.png",
  },
  {
    original: "/images/theme/g/3.jpg",
    thumbnail: "/images/theme/g/3.jpg",
  },
  {
    original: "/images/theme/g/4.jpg",
    thumbnail: "/images/theme/g/4.jpg",
  },
  {
    original: "/images/theme/g/5.jpg",
    thumbnail: "/images/theme/g/5.jpg",
  },
  {
    original: "/images/theme/g/6.jpg",
    thumbnail: "/images/theme/g/6.jpg",
  },
  {
    original: "/images/theme/g/7.jpg",
    thumbnail: "/images/theme/g/7.jpg",
  },
];

export default function Services({ setShowBookingModal }) {
  return (
    <>
      <div className="lightBg" style={{ padding: "50px 0" }}>
        <div className="container">
          <ClientSlider />
        </div>
      </div>
      <Wrapper id="packages">
        <Pricing />
        <div className="whiteBg" style={{ padding: "60px 0" }}>
          <div className="lightBg">
            <div className="container">
              <Advertising className="flexSpaceCenter">
                <AddLeft>
                  <h4 className="font15 semiBold">Datos interesantes sobre Orlando</h4>
                  <h2 className="font40 extraBold">¿Por qué Orlando?</h2>
                  <p className="font15">
                    Orlando tiene mucho que ofrecer por derecho propio, incluidas las mejores tiendas, cultura y restaurantes, pero la
                    región en su conjunto es quizás el destino más popular del mundo para vacaciones familiares y, como garantía de
                    diversión, sol y alegría, sin duda puede Encuéntralo todo en Orlando.
                  </p>
                  <ButtonsRow className="flexNullCenter contact-us-btn" style={{ margin: "30px 0" }}>
                    <div style={{ width: "200px" }}>
                      <FullButton title="Contacta con Nosotros" action={() => setShowBookingModal(true)} />
                    </div>
                    {/* <div style={{ width: "200px", marginLeft: "15px" }}>
                      <FullButton title="Contacta con Nosotros" action={() => alert("clicked")} border />
                    </div> */}
                  </ButtonsRow>
                </AddLeft>
                <AddRight>
                  <AddRightInner>
                    <div className="flexNullCenter">
                      <AddImgWrapp1 className="flexCenter">
                        <img src="/images/theme/4.png" alt="office" />
                      </AddImgWrapp1>
                      <AddImgWrapp2>
                        <img src="/images/theme/3.png" alt="office" />
                      </AddImgWrapp2>
                    </div>
                    <div className="flexNullCenter">
                      <AddImgWrapp3>
                        <img src="/images/theme/1.png" alt="office" />
                      </AddImgWrapp3>
                      <AddImgWrapp4>
                        <img src="/images/theme/2.png" alt="office" />
                      </AddImgWrapp4>
                    </div>
                  </AddRightInner>
                </AddRight>
              </Advertising>
            </div>
          </div>
        </div>
      </Wrapper>
      <Wrapper id="gallery">
        <div className="pb-5">
          <Container>
            <Row className="justify-content-md-center text-center">
              <Col lg="8">
                <HeaderInfo>
                  <h1 className="font40 extraBold">Mira algunas Fotos Increíbles</h1>
                  <p className="font15">Increíble ubicación para que tus vacaciones sean inolvidables</p>
                </HeaderInfo>
              </Col>
            </Row>

            <Row className="justify-content-md-center text-center mb-5">
              <Col lg="8">
                <ImageGallery items={images} />
              </Col>
            </Row>
          </Container>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const Advertising = styled.div`
  margin: 80px 0;
  padding: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 100px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  position: absolute;
  top: -70px;
  right: 0;
  @media (max-width: 860px) {
    width: 80%;
    position: relative;
    order: 1;
    top: -40px;
  }
`;
const AddRightInner = styled.div`
  width: 100%;
`;
const AddImgWrapp1 = styled.div`
  width: 48%;
  margin: 0 6% 10px 6%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp2 = styled.div`
  width: 30%;
  margin: 0 5% 10px 5%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp3 = styled.div`
  width: 20%;
  margin-left: 40%;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const AddImgWrapp4 = styled.div`
  width: 30%;
  margin: 0 5%auto;
  img {
    width: 100%;
    height: auto;
    border-radius: 1rem;
    box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0 2px 15px rgba(0, 0, 0, 0.3);
  }
`;
const HeaderInfo = styled.div`
  margin-bottom: 0px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
