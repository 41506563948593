import { useEffect } from "react";

import "./thank-you.css";

function ThankYou({ name, currentPhoneNumber, medium }) {
  useEffect(() => {
    if (medium === "googlesearchORLANDOSOLO") {
      window.gtag("event", "conversion", {
        send_to: `${process.env.REACT_APP_GOOGLE_SEARCH_CON_LINK}`,
      });
    } else if (medium === "googlediscoveryORLANDOSOLO") {
      window.gtag("event", "conversion", {
        send_to: `${process.env.REACT_APP_GOOGLE_DISCOVERY_CON_LINK}`,
      });
    } else {
      window.gtag("event", "conversion", {
        send_to: `${process.env.REACT_APP_GOOGLE_OTHER_CON_LINK}`,
      });
    }
  }, []);
  return (
    <div id="hotel-thank-you-component" className="thank-you center">
      <div className="row mb-4">
        <div className="col">
          <img className="step-img-icon" src="/images/step-icons/green-tick.png" alt="step" />
          <h5>{name}</h5>
          <h4>¡Gracias por tu envío!</h4>
        </div>
      </div>
      <div className="container">
        <div className="row mt-4 mb-5 d-flex justify-content-center">
          <div className="col-8">
            Uno de nuestros agentes se comunicará con usted en breve, tenga paciencia si se retrasa. O llámenos al{" "}
            <a href={`tel: ${currentPhoneNumber}`}>{currentPhoneNumber}</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ThankYou;
