import { useState } from "react";
import { DoHaveDatesAnswers } from "../../../../config/constants";

import StepButton from "../step-button/step-button";

import "./do-have-dates.css";

function DoHaveDates({ doHaveDatesAns, changeCurrentStep }) {
  const currentStep = 0;
  const [answer, setAnswer] = useState(doHaveDatesAns);

  const clickDoHaveDates = (value) => {
    setAnswer(value);

    changeCurrentStep(currentStep, 1, value);
  };

  return (
    <div className="do-you-have-dates center">
      <div className="row mb-4">
        <div className="col">
          <img className="step-img-icon" src="/images/step-icons/do-you-have-dates.svg" alt="step" />
          <h4>¿Tienes fechas específicas para viajar?</h4>
        </div>
      </div>
      <div className="row mb-2  d-flex justify-content-center">
        <div className="col-sm-6">
          <StepButton
            answer={answer}
            value={DoHaveDatesAnswers.yes}
            text="Sí Tengo"
            btnClicked={() => clickDoHaveDates(DoHaveDatesAnswers.yes)}
          />
        </div>
      </div>
      <div className="row mb-2 d-flex justify-content-center">
        <div className="col-sm-6">
          <StepButton
            answer={answer}
            value={DoHaveDatesAnswers.no}
            text="No, no Tengo"
            btnClicked={() => clickDoHaveDates(DoHaveDatesAnswers.no)}
          />
        </div>
      </div>
    </div>
  );
}

export default DoHaveDates;
