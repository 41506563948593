import { useEffect, useState } from "react";
import { Formik, Field, Form, ErrorMessage, useField, useFormikContext } from "formik";
import * as Yup from "yup";

import { BsFillArrowRightCircleFill } from "react-icons/bs";

import PhoneNumberInput from "../../PhoneNumberInput/phone-number-input";
import { phoneRegExp } from "../../../config/constants";

import CityList from "../../CityList/city-list";
import { FormGroup } from "react-bootstrap";
import { Alert } from "react-bootstrap";

import "./booking-form.css";

const validationSchema = Yup.object().shape({
  firstName: Yup.string().min(4, "Más de 4 caracteres").max(20, "Menos de 20 caracteres").required("Requerido"),
  lastName: Yup.string().min(4, "Más de 4 caracteres").max(20, "Menos de 20 caracteres").required("Requerido"),
  city: Yup.object().required("Required"),
  phoneNumber: Yup.string().matches(phoneRegExp, "El número de teléfono no es válido").required("Requerido"),
  email: Yup.string().email("Debe ser un correo electrónico válido").required("Correo electronico es requerido"),
});

const CityDropdown = ({ ...props }) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  return (
    <CityList
      changeCurrentValue={(val) => {
        setFieldValue(field.name, val);
        props.handleCityOnChange(val);
      }}
      selectedOption={field.value}
    />
  );
};

const BookingForm = ({ errorList, onSubmitClick, isLoading, location }) => {
  const [countryCode, setCountryCode] = useState(location?.countryCode);
  const onSubmitHandler = (values) => {
    onSubmitClick(values);
  };

  useEffect(() => {}, [errorList, isLoading]);

  const handleCityOnChange = (city) => {
    let tempCountryCode = city?.value.split(", ")[1];

    setCountryCode(tempCountryCode);
  };

  return (
    <>
      {!isLoading && (
        <Formik
          initialValues={{
            firstName: "",
            lastName: "",
            city: { code: "", value: "", label: "" },
            phoneNumber: "",
            email: "",
          }}
          onSubmit={onSubmitHandler}
          validationSchema={validationSchema}
        >
          <Form>
            <div className="row d-flex justify-content-center">
              <div className="col-sm-8 mt-4 mb-4">
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <FormGroup>
                      <Field name="firstName" type="text" className="form-control" placeholder="Primer Nombre" disabled={isLoading} />
                      <ErrorMessage name="firstName">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                    </FormGroup>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <FormGroup>
                      <Field name="lastName" type="text" className="form-control" placeholder="Apellido" disabled={isLoading} />
                      <ErrorMessage name="lastName">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mb-3">
                    <p className="city-p">Ingresa tu Ciudad</p>
                    <FormGroup className="input-payment-box">
                      <CityDropdown className="form-control" name="city" handleCityOnChange={handleCityOnChange} />
                      <ErrorMessage name="city">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                    </FormGroup>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 mb-3">
                    <FormGroup>
                      <Field name="email" type="text" className="form-control" placeholder="Email" disabled={isLoading} />
                      <ErrorMessage name="email">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                    </FormGroup>
                  </div>
                  <div className="col-sm-6 mb-3">
                    <FormGroup>
                      <PhoneNumberInput name="phoneNumber" className="form-control" disabled={isLoading} countryCode={countryCode} />
                      <ErrorMessage name="phoneNumber">{(msg) => <div style={{ color: "red" }}>{msg}</div>}</ErrorMessage>
                    </FormGroup>
                  </div>
                </div>
                {errorList && errorList.length > 0 && (
                  <div className="row">
                    <div className="col">
                      {errorList.map((errorMsg) => {
                        return (
                          <>
                            <Alert color="danger">
                              <p className="mb-0">{errorMsg}</p>
                            </Alert>
                          </>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12 text-center">
                      <button type="submit" className="button-submit-style" disabled={isLoading}>
                        Enviar solicitud de reserva <BsFillArrowRightCircleFill />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        </Formik>
      )}
      {isLoading && (
        <div className="center">
          <div className="row mb-4 d-flex justify-content-center">
            <div className="col">
              <img className="step-img-icon" src="images/step-icons/loading.gif" alt="step" />
              <h5>¡Espere por favor!</h5>
              <h4>Comprobando sus datos, tenga paciencia.</h4>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BookingForm;
