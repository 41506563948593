import React from "react";
import styled from "styled-components";

import ProjectBox from "../Elements/ProjectBox";
import FullButton from "../Buttons/FullButton";

export default function Projects({ currentPhoneNumber, setShowBookingModal }) {
  return (
    <>
      <Wrapper id="activities">
        <div className="whiteBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Actividades para hacer en Orlando</h1>
              <p className="font15">
                Orlando es un gran destino turístico que cuenta con una gran variedad de actividades para realizar. Algunas actividades
                increíbles se mencionan a continuación. No te pierdas esas actividades.
              </p>
            </HeaderInfo>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img="/images/theme/activity/1.jpg"
                  title="Parque temático de Orlando"
                  text="En Orlando, los visitantes, tanto jóvenes como jóvenes de corazón, pueden sumergirse en aventuras al aire libre en los parques temáticos más importantes del mundo. Ya sea la magia de Walt Disney World ® Resort, la emoción sin fin en Universal Orlando Resort, un paraíso acuático en SeaWorld ® Orlando o la construcción de diversión familiar en LEGOLAND ® Florida Resort, Orlando tiene parques temáticos perfectos para ti."
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img="/images/theme/activity/2.jpg"
                  title="Aventuras en hidrodeslizador por Boggy Creek"
                  text="Ubicado aproximadamente a una hora al sur de los principales distritos turísticos de Orlando, Boggy Creek Airboat Adventures lo invita a deslizarse por las cabeceras de los Everglades de Florida a velocidades de hasta 40 mph a bordo de botes de aire para seis pasajeros que han sido inspeccionados y aprobados por la Guardia Costera de los EE. UU."
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img="/images/theme/activity/3.jpg"
                  title="El centro comercial en Millenia"
                  text="A minutos de Universal Orlando Resort y SeaWorld®Orlando, The Mall at Millenia se enfoca en boutiques de lujo y marcas en demanda. En el interior, puede explorar 150 tiendas, servicios y restaurantes, incluidas numerosas exclusivas del mercado. Las comodidades de este centro comercial incluyen servicios de compras personales de cortesía y Wi-Fi, personal multilingüe, valet parking y cambio de divisas."
                />
              </div>
            </div>
            <div className="row textCenter">
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img="/images/theme/activity/4.jpg"
                  title="Lugar divertido América"
                  text="Fun Spot America es el único parque temático familiar de Florida Central y tiene dos ubicaciones: International Drive en Orlando y Kissimmee cerca de Walt Disney World. Ambos brindan el mismo nivel de diversión, pero son lo suficientemente distintos como para permitir experiencias completamente diferentes."
                />
              </div>
              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img="/images/theme/activity/5.jpg"
                  title="Aventuras épicas de pádel"
                  text="Epic Paddle Adventures lo invita a explorar manantiales de aguas cristalinas, buscar vida marina en la costa y disfrutar del sol local en uno de los hermosos lagos de Orlando. Se enorgullecen de brindar instrucción práctica e intensiva para que pueda sentirse cómodo en el agua y concentrarse en tener el viaje de su vida."
                />
              </div>

              <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                <ProjectBox
                  img="/images/theme/activity/6.jpg"
                  title="Paseo urbano Universal"
                  text="Universal CityWalk en Universal Orlando Resort, un universo autónomo en sí mismo, ofrece exquisita comida fina, restaurantes casualmente elegantes y entretenimiento de primera clase. Lo mejor de todo es que la entrada siempre es gratuita y el estacionamiento es gratuito después de las 6:00 p. m., excepto eventos especiales."
                />
              </div>
            </div>
            <div className="row flexCenter">
              <div style={{ margin: "50px 0", width: "200px" }}>
                <FullButton title="Reservar Paquete" action={() => setShowBookingModal(true)} />
              </div>
            </div>
          </div>
        </div>
        <div className="lightBg">
          <div className="container">
            <Advertising className="flexSpaceCenter">
              <AddLeft>
                <AddLeftInner>
                  <ImgWrapper className="flexCenter">
                    <img className="radius8" src="/images/theme/header-img-1.jpg" alt="add" />
                  </ImgWrapper>
                </AddLeftInner>
              </AddLeft>
              <AddRight>
                <h4 className="font15 semiBold">Oferta especial única</h4>
                <h2 className="font40 extraBold">Llame para obtener una oferta del 30%</h2>
                <p className="font15">
                  Puede llamarnos y reservar su plan de vacaciones con nosotros. Le daremos una oferta del 30% por esto. Solo para los
                  primeros 100 clientes obtenga esta increíble oferta. No pierdas esta oportunidad.
                </p>
                <ButtonsRow className="flexNullCenter" style={{ margin: "30px 0" }}>
                  <div style={{ width: "190px" }}>
                    <FullButton title="Reservar Paquete" action={() => setShowBookingModal(true)} />
                  </div>
                  {/* <div style={{ width: "190px", marginLeft: "15px" }}>
                  <FullButton title="Contact Us" action={() => alert("clicked")} border />
                </div> */}
                </ButtonsRow>
              </AddRight>
            </Advertising>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
`;
const HeaderInfo = styled.div`
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const Advertising = styled.div`
  padding: 100px 0;
  margin: 100px 0;
  position: relative;
  @media (max-width: 1160px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 860px) {
    flex-direction: column;
    padding: 0 0 30px 0;
    margin: 80px 0 0px 0;
  }
`;
const ButtonsRow = styled.div`
  @media (max-width: 860px) {
    justify-content: space-between;
  }
`;
const AddLeft = styled.div`
  position: relative;
  width: 50%;
  p {
    max-width: 475px;
  }
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
    text-align: center;
    h2 {
      line-height: 3rem;
      margin: 15px 0;
    }
    p {
      margin: 0 auto;
    }
  }
`;
const AddRight = styled.div`
  width: 50%;
  @media (max-width: 860px) {
    width: 80%;
    order: 2;
  }
`;
const AddLeftInner = styled.div`
  width: 100%;
  position: absolute;
  top: -300px;
  left: 0;
  @media (max-width: 1190px) {
    top: -250px;
  }
  @media (max-width: 920px) {
    top: -200px;
  }
  @media (max-width: 860px) {
    order: 1;
    position: relative;
    top: -60px;
    left: 0;
  }
`;
const ImgWrapper = styled.div`
  width: 100%;
  padding: 0 15%;
  img {
    width: 100%;
    height: auto;
  }
  @media (max-width: 400px) {
    padding: 0;
  }
`;
