import React from "react";
import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { AiFillCloseCircle } from "react-icons/ai";

import PricingTable from "../Elements/PricingTable";
import { packages } from "../../assets/data/packages";

export default function Pricing() {
  const [open, setOpen] = React.useState(false);
  const [planImg, setPlanImg] = React.useState();

  const openPlanModal = (value) => {
    setPlanImg(value);
    setOpen(true);
  };

  return (
    <>
      <Wrapper id="pricing">
        <div className="whiteBg">
          <div className="container">
            <HeaderInfo>
              <h1 className="font40 extraBold">Nuestros Paquetes de Villas</h1>
              <p className="font15">
                Puede elegir muchas variaciones de paquetes turísticos. ¡Eche un vistazo a todos los paquetes que se mencionan y elija el
                que desee!
              </p>
            </HeaderInfo>
            <TablesWrapper className="flexSpaceNull">
              <TableBox>
                <PricingTable packageData={packages[0]} action={(value) => openPlanModal(value)} />
              </TableBox>
              <TableBox>
                <PricingTable packageData={packages[1]} action={(value) => openPlanModal(value)} />
              </TableBox>
              <TableBox>
                <PricingTable packageData={packages[2]} action={(value) => openPlanModal(value)} />
              </TableBox>
            </TablesWrapper>{" "}
            <TablesWrapper className="flexSpaceNull">
              <TableBox>
                <PricingTable packageData={packages[3]} action={(value) => openPlanModal(value)} />
              </TableBox>
              <TableBox>
                <PricingTable packageData={packages[4]} action={(value) => openPlanModal(value)} />
              </TableBox>
              <TableBox>
                <PricingTable packageData={packages[5]} action={(value) => openPlanModal(value)} />
              </TableBox>
            </TablesWrapper>
          </div>
        </div>
      </Wrapper>

      <Modal size="lg" show={open} centered>
        <Modal.Body className="package-modal-body">
          <h4>Plano de La Casa</h4>
          <img src={planImg} alt="img"></img>
          <AiFillCloseCircle
            size={35}
            className="package-img-close"
            onClick={() => {
              setOpen(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

const Wrapper = styled.section`
  width: 100%;
  padding: 50px 0 0px 0px;
`;
const HeaderInfo = styled.div`
  margin-bottom: 0px;
  @media (max-width: 860px) {
    text-align: center;
  }
`;
const TablesWrapper = styled.div`
  @media (max-width: 860px) {
    flex-direction: column;
  }
`;
const TableBox = styled.div`
  width: 31%;
  @media (max-width: 860px) {
    width: 100%;
    max-width: 370px;
    margin: 0 auto;
  }
`;
