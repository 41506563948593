import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
// Components
import TestimonialBox from "../Elements/TestimonialBox";

export default function TestimonialSlider() {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <Slider {...settings}>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Disfrutamos mucho, de increíbles actividades y compras. Gracias por el increíble plan de vacaciones."
            author="Emilia Clarke"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="Compré un paquete para 10 personas, y disfrutamos mucho, Mazing Orlando!. Vendrá de nuevo."
            author="Andru Patterson"
          />
        </LogoWrapper>
        <LogoWrapper className="flexCenter">
          <TestimonialBox
            text="¡Oh, mi familia lo disfrutó! ¡Estas son mis mejores vacaciones con mi familia! gracias por su ayuda, volver de nuevo!"
            author="Michel Peyronel"
          />
        </LogoWrapper>
      </Slider>
    </div>
  );
}

const LogoWrapper = styled.div`
  width: 90%;
  padding: 0 5%;
  :focus-visible {
    outline: none;
    border: 0px;
  }
`;
