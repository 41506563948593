import React, { useEffect, useState } from "react";

import TopNavbar from "../components/Nav/TopNavbar";
import Header from "../components/Sections/Header";
import Services from "../components/Sections/Services";
import Projects from "../components/Sections/Projects";
import Blog from "../components/Sections/Blog";
import Footer from "../components/Sections/Footer";
import { getPhoneNumber } from "../meta/other";
import { getIpDetails, getLocationDetails } from "../api/other";
import Booking from "../components/Booking/booking";
import ContactUs from "../components/Sections/Contact";

export default function Landing() {
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [location, setLocation] = useState({
    ip: "",
    countryCode: "",
    country: "",
    city: "",
  });
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState("");

  //get IP address and Gio Location
  useEffect(() => {
    getIpDetails().then((data) => {
      try {
        if (data.ip) {
          getLocationDetails(data.ip).then((data) => {
            try {
              if (data.country_code) {
                setLocation({
                  ip: data?.ip,
                  countryCode: data?.country_code,
                  country: data?.country,
                  city: data?.city,
                });
                setCurrentPhoneNumber(getPhoneNumber(data?.country_code));
              } else {
                setCurrentPhoneNumber(getPhoneNumber("US"));
              }
            } catch (error) {
              setCurrentPhoneNumber(getPhoneNumber("US"));
            }
          });
        } else {
          setCurrentPhoneNumber(getPhoneNumber("US"));
        }
      } catch (error) {
        setCurrentPhoneNumber(getPhoneNumber("US"));
      }
    });
  }, []);

  return (
    <>
      <TopNavbar currentPhoneNumber={currentPhoneNumber} />
      <Header currentPhoneNumber={currentPhoneNumber} setShowBookingModal={setShowBookingModal} />
      <Services setShowBookingModal={setShowBookingModal} />
      <Projects setShowBookingModal={setShowBookingModal} currentPhoneNumber={currentPhoneNumber} />
      <Blog />
      <ContactUs />
      <Footer />
      <Booking
        location={location}
        currentPhoneNumber={currentPhoneNumber}
        showBookingModal={showBookingModal}
        setShowBookingModal={setShowBookingModal}
      />
    </>
  );
}
